(function ($) {
  Drupal.behaviors.gridRowFormatterV1 = {
    attached: false,
    isMobile: false,
    attach: function (context) {
      var self = this;
      var $formatter = $('.js-grid-row-formatter-v1', context);

      if (self.attached) {
        return;
      }
      self.attached = true;

      Unison.on('change', function () {
        self.initGrids($formatter);
      });
      self.initGrids($formatter);
    },
    initGrids: function ($formatter) {
      var self = this;

      self.isMobile = self.checkIfMobile();
      $formatter.each(function () {
        var $thisFormatter = $(this);

        self.seeAllGrids($thisFormatter);
      });
    },
    checkIfMobile: function () {
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();

      if (!!bp && !!bps) {
        return parseInt(bp.width, 10) <= parseInt(bps.portrait, 10);
      }

      return false;
    },
    seeAllGrids: function ($thisFormatter) {
      var self = this;
      var $gridRowsWrapper = $('.js-grid-row-formatter-accordion', $thisFormatter);
      var $gridItems = $('.js-grid-row-formatter-grid-item', $thisFormatter);
      var $seeAllBtn = $('.js-see-all-btn', $thisFormatter);
      var gridPerRow = $('.js-grid-row-mobile-2-column', $thisFormatter).length > 0 ? 2 : 1;
      var shownRowsNumber = 0;
      var hideGridsUnder = 0;

      if ($gridRowsWrapper.length <= 0) {
        return;
      }
      if (self.isMobile) {
        $gridRowsWrapper.addClass('grid-items-initialized');
        shownRowsNumber = parseInt($gridRowsWrapper.data('showOnlyRows'));
        hideGridsUnder = gridPerRow * shownRowsNumber;

        if (hideGridsUnder <= $gridItems.length) {
          $seeAllBtn.show();
          $gridItems.slice(hideGridsUnder).addClass('grid-item-hidden');
        }
      } else {
        $gridRowsWrapper.removeClass('grid-items-initialized all-grids-shown');
        $gridItems.removeClass('grid-item-shown grid-item-hidden');
      }

      $seeAllBtn.once().on('click', function () {
        if ($gridRowsWrapper.hasClass('all-grids-shown')) {
          $gridItems.slice(hideGridsUnder).addClass('grid-item-hidden').removeClass('grid-item-shown');
          $gridRowsWrapper.removeClass('all-grids-shown');
        } else {
          $gridItems.slice(hideGridsUnder).addClass('grid-item-shown').removeClass('grid-item-hidden');
          $gridRowsWrapper.addClass('all-grids-shown');
        }
      });
    }
  };
})(jQuery);
